import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import blogPosts from "../data/blogPosts";
import "../styles/selectedBlogPost.css";
import PasswordPrompt from "./PasswordPrompt.js";

const SelectedBlogPost = ({ post: propPost }) => {
  const { id } = useParams();
  const [hasAccess, setHasAccess] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1280);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  // If no post provided as a prop, find it by id from URL params
  let post = propPost;
  if (!post) {
    post = blogPosts.find((post) => post.id === Number(id));
  }

  // If post is still undefined, return a message or some fallback component
  if (!post && isDesktop) {
    return (
      <div>
        <img src="img/cookFinal2.png" alt="" className="cook-image" />
      </div>
    );
  }

  if (!post && !isDesktop) {
    return <div></div>;
  }

  if (post.gated === true && !hasAccess) {
    return <PasswordPrompt onCorrectPassword={() => setHasAccess(true)} />;
  }

  const content = post.content.split("\n").map((item, index) => {
    const trimmedItem = item.trim();
    const imgRegex = /^{{img:(.+?)(?:;style:(.+))?}}$/;
    const imgMatch = trimmedItem.match(imgRegex);

    if (imgMatch) {
      const imgSrc = imgMatch[1];
      const customStyle = imgMatch[2]
        ? imgMatch[2].split(";").reduce((styleObj, style) => {
            const [property, value] = style.split(":");
            return { ...styleObj, [property.trim()]: value.trim() };
          }, {})
        : {};

      const imageStyle = {
        maxWidth: "100%",
        ...customStyle,
      };

      return <img key={index} src={imgSrc} alt="" style={imageStyle} />;
    }

    return (
      <p
        className={`blog-body ${trimmedItem === "" ? "empty-paragraph" : ""}`}
        key={index}
        dangerouslySetInnerHTML={{ __html: trimmedItem }}
      ></p>
    );
  });

  return (
    <div>
      <p className="blog-title">{post.title}</p>
      <p className="blog-date">{post.date}</p>
      {content}
    </div>
  );
};

export default SelectedBlogPost;
